import ProductSelectionPopup from '@/components/productSelectionPopup/productSelectionPopup';
import productSetsModule from '@/services/productSets';
import couponModule from '@/services/coupon';
const _ = require('lodash');

export default {
  components: {
    ProductSelectionPopup
  },
  data() {
    let validateNumber = (value, min, max, callback, label) => {
      if (isNaN(value)) {
        callback(new Error(label + ' must be a number.'));
        return false;
      }

      if (value < min) {
        callback(new Error(label + ' can not be less than 0'));
        return false;
      }
      if (value > max) {
        callback(new Error(label + ' can not be greater than ' + max));
        return false;
      }

      callback();
      return true;
    };

    let validateDiscountValue = (rule, value, callback) => {
      // Max amount
      let maxAmount = 100;
      let label = 'Discount amount';
      if (this.coupon.rule != 'percentage') {
        maxAmount = 10000000;
        label = 'Discount percentage';
      }

      // Validate Number
      validateNumber(value, 0, maxAmount, callback, label);
    };

    let validateCouponName = (value, callback) => {
      if (/^([a-zA-Z0-9 _-]+)$/.test(value)) {
        callback();
      } else {
        callback(new Error('Only alphabets, digits,-,_ and space allowed.'));
      }
    };

    return {
      fetchingData: false,
      creatingCoupon: false,

      isEditMode: false,
      couponId: null,

      coupon: {
        name: '',
        prefix: '',
        usage_limit: 'once',

        type: 'unique',
        rule: 'fixedAmount',
        value: 0,
        min_order: 0,

        applicable_on: 'order',
        applicable_filter: [],

        expiry_type: 'relative',
        expiry_value: 48,
        expiry_unit: 'hour'
      },

      couponFormRules: {
        name: [
          {
            required: true,
            message: 'Coupon name is required.',
            trigger: 'blur'
          },
          {
            min: 3,
            max: 300,
            message: 'Length should be of 3 to 300 characters.',
            trigger: 'blur'
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              validateCouponName(value, callback, 'Coupon Name');
            }
          }
        ],
        prefix: [
          {
            max: 5,
            message: 'Prefix can not exceed 5 characters.',
            trigger: 'blur'
          }
        ],
        expiry_value: [
          {
            required: true,
            message: 'Expiry time is required.',
            trigger: 'blur'
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              validateNumber(value, 0, 10000, callback, 'Expiry time');
            }
          }
        ],
        value: [
          {
            required: true,
            message: 'This field is required.',
            trigger: 'blur'
          },
          {
            trigger: 'blur',
            validator: validateDiscountValue
          }
        ],
        min_order: [
          {
            required: true,
            message: 'Minimum order value is required.',
            trigger: 'blur'
          },
          {
            trigger: 'blur',
            validator: (rule, value, callback) => {
              validateNumber(value, 0, 1000000, callback, 'Minimum order value');
            }
          }
        ]
      },

      // Product Collection Variables
      selectedCollectionToInclude: null,
      productCollectionList: null
    };
  },

  methods: {
    //#region -------- Collection Mgt Popup

    async fetchProductCollections() {
      try {
        let collectionList = await productSetsModule.getAllCollections(this);
        this.productCollectionList = collectionList.data;
      } catch (err) {
        console.error('Failed to fetch product collectons.', err);
        this.reportError(err);
      }
    },

    onIncludeCollection() {
      // If collection is already added, skip
      let item = _.find(this.coupon.applicable_filter, (coll) => {
        return coll.id == this.selectedCollectionToInclude.id;
      });
      if (item) {
        this.selectedCollectionToInclude = null;
        this.warningToast('Collection is already selected.');
        return;
      }

      this.coupon.applicable_filter.unshift(this.selectedCollectionToInclude);
      this.selectedCollectionToInclude = null;
    },

    onRemoveIncludedCollection(index) {
      this.coupon.applicable_filter.splice(index, 1);
    },

    //#endregion

    //#region -------- Product Mgt Methods

    onShowProductsPopup() {
      this.$refs.productSelectPopup.showPopup();
    },

    removeProductFromList(index) {
      this.coupon.applicable_filter.splice(index, 1);
    },

    onAddSelectedProducts(productList) {
      console.log('selected products are', productList);

      let newList = this.coupon.applicable_filter;
      for (let i = 0; i < productList.length; i++) {
        // If prdouct is already added, skip
        let item = _.find(newList, (currentProd) => {
          return currentProd.id == productList[i].id;
        });
        if (!item) newList.unshift(productList[i]);
      }

      this.coupon.applicable_filter = newList;
    },

    //#endregion

    onCouponTypeChange() {
      if (this.coupon.type == 'unique') {
        this.coupon.expiry_type = 'relative';
        this.coupon.expiry_value = 48;
        this.coupon.expiry_unit = 'hour';
      } else {
        this.coupon.expiry_type = 'date';
        this.coupon.expiry_value = null;
      }
    },

    async onCouponApplicableChange() {
      this.coupon.applicable_filter = [];
      if (this.coupon.applicable_on == 'collection') {
        if (!this.productCollectionList) await this.fetchProductCollections();
      }
    },

    //#region -------- Create & Update

    async validateForm() {
      // Validate product list or collection list if applicable.
      if (this.coupon.applicable_on == 'product' && this.coupon.applicable_filter.length == 0) {
        this.warningToast('Please select products applicable for discount.');
        return false;
      } else if (this.coupon.applicable_on == 'collection' && this.coupon.applicable_filter.length == 0) {
        this.warningToast('Please select collections applicable for discount.');
        return false;
      }

      return new Promise((resolve) => {
        this.$refs['couponForm'].validate((valid) => {
          resolve(!valid ? false : true);
        });
      });
    },

    async onCreateCoupon() {
      try {
        // Validate Form
        let isFormValid = await this.validateForm();
        if (!isFormValid) return;

        this.creatingCoupon = true;

        let params = JSON.parse(JSON.stringify(this.coupon));

        let filters = [];
        for (let i = 0; i < params.applicable_filter.length; i++) {
          filters.push(params.applicable_filter[i].id);
        }
        params.applicable_filter = JSON.stringify(filters);
        let result = await couponModule.createCoupon(params);

        if (result.data.success) {
          this.successToast('Coupon Created.');
          this.$router.replace('/coupon');
        } else {
          this.errorToast(result.data.message);
        }

        this.creatingCoupon = false;
      } catch (err) {
        this.creatingCoupon = false;
        console.error(err);
        this.errorToast('Failed to create coupon, please contact support team.');
      }
    },

    async onUpdateCoupon() {
      try {
        // Validate Form
        let isFormValid = await this.validateForm();
        if (!isFormValid) return;

        this.creatingCoupon = true;

        let params = JSON.parse(JSON.stringify(this.coupon));

        let filters = [];
        for (let i = 0; i < params.applicable_filter.length; i++) {
          let id = null;
          if (this.coupon.applicable_on == 'product') id = params.applicable_filter[i].product_id;
          if (this.coupon.applicable_on == 'collection') id = params.applicable_filter[i].collection_id;
          if (id) filters.push(id);
        }
        params.applicable_filter = JSON.stringify(filters);

        let result = await couponModule.updateCoupon(this.couponId, params);

        if (result.data.success) {
          this.successToast('Changes Saved.');
        } else {
          this.errorToast(result.data.message);
        }

        this.creatingCoupon = false;
      } catch (err) {
        this.creatingCoupon = false;
        console.error(err);
        this.errorToast('Failed to update coupon, please contact support team.');
      }
    },

    async showDeleteConfirm() {
      this.$swal({
        title: 'Delete Coupon?',
        text: "You won't be able to undo this!",
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Delete Coupon'
      }).then(() => {
        this.deleteCoupon();
      });
    },

    async deleteCoupon() {
      try {
        let result = await couponModule.deleteCoupon(this.couponId, this);
        if (!result.data.success) {
          // Prepare li for each campaign.
          let liList = [];
          for (let i = 0; i < result.data.campaigns.length; i++) {
            let campaign = result.data.campaigns[i];
            console.log('campaign is', campaign);
            liList.push(`<li>${campaign.name} ( ${campaign.channel.toUpperCase()} - ${campaign.type.toUpperCase()})</a>`);
          }

          let html = `
            This coupon is already being used in following campaigns. To delete the coupon, you will need to remove coupon from following campaigns.
            <br /><br /><ol>${liList.join('')}</ol>
          `;
          this.$alert(html, 'Coupon is already being used', {
            confirmButtonText: 'Okay',
            customClass: 'errorPopup',
            dangerouslyUseHTMLString: true
          });
          return false;
        }

        this.$router.replace('/coupon');
        this.successToast('Coupon Deleted.');
      } catch (err) {
        console.error(err);
        this.errorToast('Something went wrong, please contact support team.');
      }
    },

    //#endregion -------- Create & Update

    async fetchDetailsForEdit() {
      this.fetchingData = true;
      try {
        let result = await couponModule.getDetails(this.couponId, this);
        if (!result.data) {
          // Show 404
          this.$router.replace('/404');
          return;
        }

        this.isEditMode = true;
        this.couponId = result.data.id;

        this.coupon = result.data;
        this.coupon.applicable_filter = JSON.parse(this.coupon.applicable_filter);

        this.fetchingData = false;
      } catch (err) {
        this.fetchingData = false;
        this.errorToast('Something went wrong, please contact support team.');
        console.error(err);
      }
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.isEditMode = true;
      this.couponId = this.$route.params.id;
      this.fetchDetailsForEdit();
    } else {
      this.showTemplatesDialog();
    }
  }
};
