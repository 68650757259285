<template>
  <div id="CreateCouponPage">
    <!-- Loader -->
    <div class="loader" v-if="fetchingData" v-loading="true"></div>

    <template v-else>
      <!-- Header -->
      <div class="header">
        <span class="campaignHeaderTitle">
          <a @click="$router.push({ path: '/coupon' })" style="cursor: pointer"> <i class="el-icon-back"></i></a>
          <span style="padding-left: 3px">New Coupon</span>
        </span>
        <div class="rightSideButtons">
          <el-button v-if="!isEditMode" type="primary" :loading="creatingCoupon" @click="onCreateCoupon">Create Coupon</el-button>
          <template v-else>
            <el-button type="primary" :loading="creatingCoupon" @click="onUpdateCoupon">Update Coupon</el-button>
            <el-button type="danger" @click="showDeleteConfirm">Delete</el-button>
          </template>
        </div>
      </div>

      <el-row :gutter="20">
        <el-col :span="17">
          <el-form :model="coupon" ref="couponForm" :rules="couponFormRules">
            <!-- Coupon Details Card -->
            <el-card class="box-card couponDetailsCard" shadow="never">
              <div slot="header">
                <span>Coupon Details</span>
              </div>

              <el-row :gutter="25">
                <el-col :span="9">
                  <el-form-item label="Coupon Name" prop="name">
                    <el-input placeholder="Ex: First Purchase Coupon" style="width: 100%" v-model="coupon.name"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="15">
                  <el-form-item label="Coupon Prefix (Optional)" prop="publicVapidKey">
                    <el-popover placement="bottom" style="position: absolute; top: 0px; right: 0px" width="300" trigger="hover" content="Coupon prefix contains letters and numbers. Coupon codes will be generted by 8 random letters and numbers at the end of your prefix. If you don't specify prefix, it will be just the 8 random letters and numbers.">
                      <i slot="reference" class="el-icon-info" style="position: absolute; top: 0px; right: 0px"></i>
                    </el-popover>
                    <el-input v-model="coupon.prefix"></el-input>
                  </el-form-item>
                </el-col>

                <!-- Discount Type -->
                <el-col :span="9">
                  <el-form-item label="Coupon Usage Limit" prop="type" class="discountTypeRadio">
                    <el-popover placement="bottom" style="position: absolute; top: 0px; right: 0px" width="300" trigger="hover" content="Howmany times customer can use the same coupon?">
                      <i slot="reference" class="el-icon-info" style="position: absolute; top: 0px; right: 0px"></i>
                    </el-popover>
                    <el-select v-model="coupon.usage_limit" style="width: 100%">
                      <el-option label="Once Per Customer" value="once"></el-option>
                      <el-option label="Multiple Per Customer" value="multiple"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <!-- Expiry Time -->
                <el-col :span="6">
                  <el-form-item v-if="coupon.expiry_type == 'relative'" label="Expire Coupon After" prop="expiry_value" class="discountTypeRadio">
                    <el-input type="number" placeholder="Enter Expiry Time" v-model="coupon.expiry_value" class="input-with-select" autocomplete="new-password">
                      <el-select v-model="coupon.expiry_unit" style="width: 90px" slot="append">
                        <el-option label="Hours" value="hour"></el-option>
                        <el-option label="Days" value="day"></el-option>
                      </el-select>
                    </el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-card>

            <!-- Discount Rules & Expiry Time -->
            <el-row :gutter="25">
              <!-- Discount Rules Card -->
              <el-col :span="24">
                <el-card class="box-card discountRulesCard" shadow="never">
                  <div slot="header">
                    <span>Discount Rules</span>
                  </div>

                  <!-- Discount Type & Value Block -->
                  <div class="dividerBlock">
                    <el-row :gutter="20">
                      <!-- Discount Type -->
                      <el-col :span="8">
                        <el-form-item label="Discount Type" prop="type" class="discountTypeRadio">
                          <el-select v-model="coupon.rule" style="width: 100%">
                            <el-option label="Fixed Amount" value="fixedAmount"></el-option>
                            <el-option label="Percentage" value="percentage"></el-option>
                            <el-option label="Free Shipping" value="freeShipping"></el-option>
                          </el-select>
                        </el-form-item>
                      </el-col>

                      <!-- Discount Amount -->
                      <el-col :span="8">
                        <!-- Fixed Amount Value -->
                        <el-form-item v-if="coupon.rule == 'fixedAmount'" label="Discount Amount" prop="value">
                          <el-input type="number" placeholder="Amount" v-model="coupon.value">
                            <template slot="prepend">INR</template>
                            <template slot="append">Off</template>
                          </el-input>
                        </el-form-item>

                        <!-- Percentage Value -->
                        <el-form-item v-else-if="coupon.rule == 'percentage'" label="Percentage Off" prop="value">
                          <el-input type="number" placeholder="Amount" v-model="coupon.value">
                            <template slot="append">% Off</template>
                          </el-input>
                        </el-form-item>

                        <!-- Max Shipping Rate -->
                        <el-form-item v-else label="Maximum Shipping Rate" prop="value">
                          <el-input type="number" placeholder="Amount" v-model="coupon.value">
                            <template slot="prepend">INR</template>
                          </el-input>
                        </el-form-item>
                      </el-col>

                      <!-- Minimum Order Value For Discount -->
                      <el-col :span="8">
                        <el-form-item label="Mininum Order Value" prop="min_order">
                          <el-input placeholder="Amount" v-model="coupon.min_order">
                            <template slot="prepend">INR</template>
                          </el-input>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>

                  <!-- Discount Product & Country Filters -->
                  <div class="dividerBlock" v-if="coupon.rule != 'freeShipping'">
                    <!-- Discount Applicable On -->
                    <el-form-item label="Discount Applicable On" class="discountTypeRadio">
                      <el-radio-group v-model="coupon.applicable_on" size="small" @change="onCouponApplicableChange">
                        <el-radio label="order" border>Entire Order</el-radio>
                        <el-radio label="product" border>Specific Products</el-radio>
                        <el-radio label="collection" border>Specific Collections</el-radio>
                      </el-radio-group>
                    </el-form-item>

                    <!-- Applicable - Product List  -->
                    <el-card class="productListCard" v-if="coupon.applicable_on == 'product'">
                      <div slot="header">
                        Products Applicable For Discount
                        <el-button type="primary" size="mini" style="float: right; margin-top: -5px" @click="onShowProductsPopup">Add Products</el-button>
                      </div>
                      <div class="noDataList" v-if="coupon.applicable_filter.length == 0">No Products Selected Yet.</div>
                      <div class="product-item" v-for="(product, index) in coupon.applicable_filter" :key="product.id">
                        <!-- Product Image -->
                        <div class="productImage" :style="{ 'background-image': `url(${product.image})` }"></div>

                        <!-- Product Name & Pricing -->
                        <div class="productRightSide">
                          <div class="productName">{{ product.name ? product.name.substring(0, 85) + (product.name.length > 100 ? '...' : '') : product.name }}</div>
                          <div class="productPrice">INR {{ product.price }}</div>
                        </div>

                        <!-- Product Select Radio Box -->
                        <div class="productRemove" @click="removeProductFromList(index)">
                          <i class="el-icon-delete"></i>
                        </div>
                      </div>
                    </el-card>

                    <!-- Applicable - Collection List  -->
                    <div class="collectionFilters" v-if="coupon.applicable_on == 'collection'">
                      <label class="label"> Collections Applicable For Discount </label>
                      <el-select filterable v-model="selectedCollectionToInclude" @change="onIncludeCollection" placeholder="Select Collection To Include">
                        <el-option v-for="collection in productCollectionList" :key="collection.id" :label="collection.name" :value="collection"> </el-option>
                      </el-select>
                      <div class="collectionList" v-if="coupon.applicable_filter.length != 0">
                        <el-row class="collectionItem" v-for="(coll, index) in coupon.applicable_filter" :key="index">
                          <el-col :span="23">
                            {{ coll.name }}
                          </el-col>
                          <el-col :span="1">
                            <i class="el-icon-delete deleteBtn" @click="onRemoveIncludedCollection(index)"></i>
                          </el-col>
                        </el-row>
                      </div>
                      <div class="noDataList" v-else>No Collections Selected Yet.</div>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </el-form>
        </el-col>

        <el-col class="coupon-how-to" :span="7">
          <el-card class="box-card couponDetailsCard" shadow="never">
            <div slot="header">
              <span>How Coupon Works?</span>
            </div>
            <ul>
              <li><strong>Coupon For Each Customer:</strong>You can create coupons to provide discounts to individual customers while sending campaign messages. Each customer will receive a unique coupon code which is valid only for the customer to whom the coupon was sent.</li>
              <li><strong>Using coupons in campaign:</strong> You can use coupon email blocks for mentioning coupon code in emails. For rest of the channels, you can mention coupon code just like you mention dynamic customer property values.</li>
            </ul>
          </el-card>
        </el-col>
      </el-row>
    </template>

    <ProductSelectionPopup ref="productSelectPopup" @onAddSelectedProducts="onAddSelectedProducts"></ProductSelectionPopup>
  </div>
</template>

<style lang="scss" src="./couponCreate.scss"></style>

<script>
import couponCreateComponent from './couponCreateComponent';
export default couponCreateComponent;
</script>
